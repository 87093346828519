//Footer
.safari {
  background: #000;
  .no-touch {
    .footer {
      top: 0;
      height: calc(100vh + 200px);
    }
  }
}
.no-touch {
  .footer {
    z-index: 1;
    min-height: 0;
    top: -200px;
    &.home {
      .scene {
        &__foreground {
          bottom: -200px;
        }
      }
    }
    .info {
      position: fixed;
    }
    .scene {
      &__foreground {
        position: fixed;
        bottom: 0px;
      }
      &__water,
      &__hills-front,
      &__hills-back,
      &__city,
      &__bird {
        position: fixed;
      }
      &__sun {
        position: fixed;
        top: 100px;
        margin-left: -130px;
        @include breakpoint(740px, max-height) {
          top: 40px;
        }
        @include breakpoint(500px) {
          margin-left: -100px;
        }
        &--footer {
          transform: translateY(var(--rise));
        	will-change: transform;
        }
      }
    }
  }
}
.footer {
  z-index: 9999;
  min-height: 840px;
  &.home {
    display: none;
  }
  &.show {
    display: block;
    opacity: 1;
  }
  .info {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 0 20px 20px 20px;
    display: flex;
    align-items: flex-start;
    color: $white;
    @include breakpoint(550px) {
      padding-bottom: 10px;
    }
    &__icon {
      width: 80px;
      height: 80px;
      position: relative;
      margin-right: 40px;
      @include breakpoint(650px) {
        margin-right: 20px;
      }
    }
    &__details {
      margin-top: 20px;
    }
    p {
      font-size: .875em;
      line-height: 1.6;
    }
  }
  .social {
    margin-bottom: 10px;
    max-width: 340px;
    li {
      margin: 0;
      width: 30px;
      display: inline-block;
      margin-right: 10px;
      &:hover {
        .f-icon {
          fill: $purple;
        }
      }
    }
    svg {
      height: 30px;
    }
  }
  .ground,
  .cap,
  .sniper,
  .snipe,
  .wheels,
  .bikeframe,
  .helmet,
  .speaker,
  .cat-a {
    fill: $teal !important;
  }
  .city {
    fill: #5A999A;
  }
  .thanks {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 10px;
    .p {
      margin-bottom: 15px;
      padding-bottom: 15px;
      display: block;
      border-bottom: 1px solid $purple;
    }
    strong {
      display: block;
      font-weight: 700;
      font-size: 1.7em;
    }
    .button {
      padding: 10px 20px;
      font-size: .875em;
    }
  }
  .form {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0;
    z-index: 9000;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
    &.active {
      display: flex;
      @include animated(.5s);
      @include fadeIn();
      form {
        animation-delay: .3s;
        @include animated(.5s);
        @include fadeInUp();
      }
    }
    &:after {
      @include content100;
      position: fixed;
      background-image: linear-gradient(-180deg, #3888A1 0%, #8CC9CC 20%, #EED097 70%);
      opacity: .96;
    }
    .close {
      width: 30px;
      height: 30px;
      position: fixed;
      right: 40px;
      top: 40px;
      z-index: 1000;
      cursor: pointer;
      transition: $transition;
      @include breakpoint(570px, max-height) {
        top: 20px;
        right: 20px;
        width: 20px;
        height: 20px;
      }
    }
    form {
      opacity: 0;
      max-width: 680px;
      padding: 40px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      z-index: 100;
    }
    fieldset {
      width: 100%;
      margin-bottom: 10px;
    }
    .button {
      display: inline-block;
      margin-top: 20px;
      font-size: .875em;
      &:hover {
        background: $teal;
        color: $white;
      }
    }
    label {
      display: block;
      width: 100%;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-family: $oswald;
      font-size: .875em;
      margin-bottom: 10px;
    }
    input,
    textarea {
      appearance: none;
      outline: none;
      border: none;
    }
    textarea {
      resize: none;
      height: 100px;
      line-height: 1.4;
    }
    input[type="text"],
    input[type="email"],
    textarea {
      width: 100%;
      font-size: .875em;
      background: rgba(255,255,255,.3);
      color: $purple;
      border: none;
      padding: 10px;
      transition: $transition;
      &:focus {
        background: rgba(255,255,255,.8);
      }
    }

  }
  .scene {
    &__foreground {
      position: absolute;
      bottom: 0px;
    }
    &__sun {
      position: absolute;
      width: 260px;
      height: 260px;
      left: 50%;
      top: 100px;
      font-size: .875em;
      letter-spacing: 5px;
      text-transform: uppercase;
      font-family: $oswald;
      text-decoration: none;
      color: $purple;
      @include breakpoint(500px) {
        width: 200px;
        height: 200px;
        font-size: 70%;
      }
      &--footer {
        &.active {
          opacity: 0;
        }
      }
      &:hover {
        .button {
          transform: scale(1.04);
        }
      }
    }
    &__water {
      background-image: radial-gradient(51% 160%, #CBDCC7 16%, #8ECBCE 100%);
    }
  }
}
