//Cover fit
.cover-fit {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: -50%;
  left: -50%;
  right: -50%;
  bottom: -50%;
  margin: auto;
}
