//Grid Layout
.grid {
  position: relative;
  z-index: 100;
  background: $white;
  padding: 100px 0;
  @include breakpoint(700px) {
    padding: 100px 0;
  }
  .container {
    @include breakpoint(600px) {
      padding: 0 10px;
    }
  }
  &-intro {
    padding-bottom: 200px;
    @include breakpoint(600px) {
      padding-bottom: 0;
    }
    .container {
      display: flex;
      position: relative;
      align-items: center;
    }
    h1 {
      @include h1(0px);
      @include breakpoint(900px) {
        font-size: 3em;
      }
    }
    &__content {
      width: 42%;
      position: relative;
      z-index: 100;
      padding-right: 20px;
      @include breakpoint(700px) {
        width: 50%;
      }
      @include breakpoint(600px) {
        padding: 20px;
        background: $white;
        width: 100%;
        position: relative;
        z-index: 1;
      }
      .details {
        padding: 30px;
        position: relative;
        z-index: 10;
        background: $white;
        transform: translateX(200px);
        @include breakpoint(1060px) {
          transform: translateX(60px);
        }
        @include breakpoint(880px) {
          transform: translateX(0);
          padding: 0;
          margin-top: 20px;
          background: none;
        }
        p {
          @include p;
        }
      }
    }
    &__circle {
      width: 58%;
      position: relative;
      @include breakpoint(700px) {
        width: 50%;
        padding-right: 20px;
        position: absolute;
        right: 0;
        top: 0;
      }
      @include breakpoint(600px) {
        display: none;
      }
      .image {
        display: block;
        img {
          border-radius: 50%;
        }
      }
    }
    .corner {
      position: absolute;
      right: 0;
      width: 370px;
      max-width: 90%;
      bottom: -150px;
      padding: 10px;
      background: $white;
    }
  }
  &__bg {
    position: fixed;
    z-index: -1;
    width: 420px;
    height: 200%;
    &.hide {
      opacity: 0;
      visibility: hidden;
    }
    &:before {
      transform: rotate(45deg);
      @include content100;
      background: $white;
      box-shadow: $shadow;
    }
  }
  &__bg1 {
    top: -40%;
    width: 300px;
    height: 300%;
    left: 30%;
    transform: translateY(var(--bg1));
    &:before {
      transform: rotate(-45deg);
    }
  }
  &__bg2 {
    top: -60%;
    left: 0;
    transform: translateX(var(--bg2));
  }
  &-section {
    width: 100%;
    .content {
      padding: 20px;
      width: calc(100% / 3);
    }
    &__header {
      width: 100%;
      padding: 0 15px;
      margin-top: -50px;
      margin-bottom: 10px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      @include breakpoint(900px) {
        margin-top: 0px;
      }
      @include breakpoint(530px) {
        padding: 0;
      }
      h2 {
        width: 100%;
        @include h2(10px);
        @include breakpoint(530px) {
          margin-left: 10px;
        }
      }
      .grid-section__item {
        margin-left: -15px;
        position: relative;
        width: 580px;
        .image {
          display: block;
          width: 100%;
        }
        @include breakpoint(940px) {
          width: 420px;
        }
        @include breakpoint(740px) {
          width: 59%;
        }
        @include breakpoint(530px) {
          width: 100%;
          margin-left: 0;
        }
      }
      .content {
        padding: 30px;
        background: $white;
        position: absolute;
        left: 310px;
        width: 420px;
        top: 50%;
        transform: translateY(-50%);
        @include breakpoint(1080px) {
          width: 340px;
        }
        @include breakpoint(940px) {
          position: relative;
          width: 100%;
          left: 0;
          top: 0;
          transform: translate(0);
        }
        blockquote {
          @include p(0);
          color: $teal;
          &:first-child:first-letter {
            font-size: 4em;
            line-height: 44px;
            padding-right: 5px;
            padding-top: 0px;
            margin-left: -10px;
            font-family: $oswald;
            font-weight: 700;
            text-transform: uppercase;
            float: left;
            position: relative;
          }
        }
        cite {
          @include cite;
        }
      }
      a.logo {
        border: 1px solid rgba(0,0,0,.2);
        transition: $dt;
        &:hover {
          border-color: $red;
        }
      }
      .logo {
        width: calc(100% - 820px);
        max-height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        @include breakpoint(1200px) {
          width: calc(100% - 700px);
          padding: 10px;
        }
        @include breakpoint(940px) {
          width: 200px;
          margin-top: 0;
        }
        @include breakpoint(530px) {
          display: none;
        }
        img {
          width: 200px;
          max-height: 100%;
          display: block;
        }
      }
    }
    &__grid {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }
    &__item {
      width: calc(100% / 3);
      position: relative;
      padding: 15px;
      @include breakpoint(900px) {
        width: 50%;
      }
      @include breakpoint(530px) {
        width: 100%;
        padding: 8px;
      }
      .quote {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        background: rgba(255,255,255,.5);
        padding: 30px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        blockquote {
          @include p(0);
          color: $teal;
        }
        cite {
          @include cite;
        }
      }
      .soon {
        position: absolute;
        left: 15px;
        top: 15px;
        width: calc(100% - 30px);
        height: calc(100% - 30px);
        background: $black-grad-3;
        color: $white;
        font-family: $oswald;
        align-items: center;
        justify-content: center;
        display: flex;
        z-index: 1000;
        text-transform: uppercase;
        letter-spacing: 3px;
        font-size: .875em;
        @include breakpoint(530px) {
          left: 8px;
          top: 8px;
          width: calc(100% - 16px);
          height: calc(100% - 16px);
        }
        span {
          display: inline-block;
        }
      }
      .image {
        width: 100%;
        position: relative;
        transition: $dt;
        &:after {
          @include content100;
          box-shadow: $shadow-lrg;
          transition: $dt;
          z-index: -1;
          opacity: 0;
        }
        &:before {
          @include content100;
          box-shadow: $shadow;
          transition: $dt;
        }
        img {
          display: block;
        }
        &__contents {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          transition: $dt;
          &:before {
            @include content100;
            background: $black-grad;
          }
          .play {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 10;
            display: flex;
            align-items: center;
            justify-content: center;
            display: none;
            svg {
              width: 80px;
              height: 80px;
              @include breakpoint(500px) {
                width: 50px;
                height: 50px;
              }
            }
          }
          .download {
            position: absolute;
            right: 0px;
            bottom: 0px;
            width: 50px;
            height: 50px;
            z-index: 100;
            background: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: $dt;
            svg {
              width: 20px;
            }
            &:hover {
              background: $red;
              .a-download {
                fill: $white;
              }
            }
          }
        }
      }
    }
  }
}
.no-touch .grid-section__item .image {
  &:hover {
    transform: translateY(-5px);
    &:before {
      opacity: 0;
    }
    &:after {
      opacity: 1;
    }
    .image__contents {
      opacity: 1;
    }
    .play {
      display: flex;
    }
  }
}
.touch .grid-section__item .image {
  &.active {
    transform: translateY(-5px);
    &:before {
      opacity: 0;
    }
    &:after {
      opacity: 1;
    }
    .image__contents {
      opacity: 1;
    }
    .play {
      display: flex;
    }
  }
}
