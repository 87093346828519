//Scene
.touch {
  .scene__sky--dark {
    display: none;
  }
}
.no-touch {
  .scene {
    &__sun {
      position: fixed;
    }
  }
}
.scene {
  width: 100%;
  overflow-x: hidden;
  height: 100vh;
  min-height: 750px;
  padding: 100px 0;
  background: #2A2C32;
  position: relative;
  &.hide {
    opacity: 0;
  }
  &__sun {
    position: absolute;
    width: 220px;
    height: 220px;
    left: 50%;
    transform: translateX(-50%);
    top: 120px;
    z-index: 2;
    &:hover {
      .hello {
        display: block;
        @include animated(.375s);
        @include fadeInLeft;
      }
    }
    .hello {
      display: none;
      position: absolute;
      left: 50%;
      bottom: 20px;
      text-transform: uppercase;
      color: $white;
      font-family: $oswald;
      font-weight: 700;
      white-space: nowrap;
      font-size: .875em;
      letter-spacing: 3px;
      @include breakpoint(600px) {
        font-size: .75em;
        letter-spacing: 2px;
      }
    }
    @include breakpoint(500px) {
      width: 180px;
      height: 180px;
    }
    &:hover, &:focus {
      &:before, &:after {
        opacity: .25;
      }
    }
    &:after, &:before {
      content: '';
      top: 50%;
      left: 50%;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      opacity: .19;
      transform: translate(-50%, -50%) scale(1.26);
      transition: .2s all ease-in;
      background-image: radial-gradient(50% 150%, #F69336 50%, #F68E52 100%);
      z-index: -1;
      animation-iteration-count: infinite;
    }
    &:after {
      @include animated(2.5s);
      animation-name: sunGlow1;
    }
    &:before {
      @include animated(2.2s);
      transform: translate(-50%, -50%) scale(1.5);
      animation-name: sunGlow2;
    }
    svg {
      width: 100%;
    }
  }
  &__sky {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    &--light {
      background-image: linear-gradient(-180deg, #3E436F 0%, #D85F6C 50%, #D94E35 100%);
    }
    &--blue {
      background-image: linear-gradient(-180deg, #3888A1 0%, #8CC9CC 20%, #EED097 70%);
      border: 1px solid #979797;
    }
  }
  &__water {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 250px;
    z-index: 2;
    background-image: radial-gradient(51% 160%, #D67A56 16%, #9E3150 100%);
  }
  &__city {
    position: absolute;
    left: 0;
    bottom: 190px;
    width: 100%;
    z-index: 5;
    svg {
      width: 100%;
      min-width: 1300px;
    }
  }
  &__hills-front {
    position: absolute;
    left: 0;
    bottom: 200px;
    width: 100%;
    z-index: 4;
    transition: $transition;
    @include breakpoint(600px) {
      left: -200px;
    }
    svg {
      width: 100%;
      min-width: 1300px;
    }
  }
  &__hills-back {
    position: absolute;
    left: 200px;
    bottom: 200px;
    width: 100%;
    z-index: 3;
    transition: $transition;
    svg {
      width: 100%;
      min-width: 1300px;
    }
    @include breakpoint(600px) {
      left: 0px;
    }
    @include breakpoint(360px) {
      left: -100px;
    }
  }
  &__foreground {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 10;
    bottom: 0;
    backface-visibility: hidden;
    transform: translateY(var(--fg));
    will-change: transform;
    svg {
      width: 100%;
    }
    &__left {
      width: 100%;
      min-width: 1300px;
    }
    &__right {
      width: 270px;
      position: absolute;
      right: 0;
      top: 27%;
      @include breakpoint(1560px, min-width) {
        margin-top: 1%;
      }
    }
  }
  &__items {
    .hidden {
      display: none;
    }
    li {
      position: absolute;
      transition: $transition;
      &:hover {
        .1tooltip {
          display: block;
          @include animated(.375s);
          @include fadeIn;
        }
      }
    }
    .tooltip {
      display: none;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -60px;
      border-radius: 3px;
      padding: 10px 20px;
      background: rgba(246,157,54,.4);
      color: $white;
      text-align: center;
    }
    &__speedbag {
      left: 0;
      top: -260px;
      width: 230px;
      @include breakpoint(1560px, min-width) {
        margin-top: 1%;
      }
      @include breakpoint(450px) {
        width: 210px;
        top: -240px;
      }
      @include breakpoint(360px) {
        width: 180px;
        top: -200px;
      }
      &:hover {
        .bag {
          fill: $white;
        }
      }
      .tooltip {
        width: 160px;
      }
    }
    &__sniper {
      left: 0;
      top: -145px;
      width: 120px;
      @include breakpoint(1560px, min-width) {
        margin-top: 1%;
      }
      @include breakpoint(450px) {
        left: -10px;
        width: 90px;
        top: -100px;
      }
      &:hover {
        .sniper {
          fill: $white;
        }
      }
      .tooltip {
        width: 140px;
        left: 70%;
        @include breakpoint(550px) {
          left: 90%;
        }
      }
    }
    &__bike {
      width: 200px;
      left: 10%;
      top: -45px;
      @include breakpoint(1560px, min-width) {
        margin-top: 1%;
      }
      @include breakpoint(450px) {
        width: 180px;
        top: -38px;
      }
      @include breakpoint(360px) {
        width: 150px;
        top: -28px;
      }
      &:hover {
        .bikeframe {
          fill: $white;
        }
      }
    }
    &__helmet {
      width: 80px;
      left: 32%;
      top: 10px;
      @include breakpoint(1560px, min-width) {
        margin-top: 1%;
      }
      @include breakpoint(1050px) {
        left: 45%;
      }
      @include breakpoint(640px) {
        left: auto;
        right: 23%;
      }
      @include breakpoint(450px) {
        width: 60px;
        top: 25px;
      }
      &:hover {
        .helmet {
          fill: $white;
        }
      }
      .tooltip {
        top: -50px;
      }
    }
    &__speaker,
    &__cat {
      width: 90px;
      right: 0;
      top: -40px;
      cursor: pointer;
      &:hover {
        .cat-a {
          fill: #B94C1A;
        }
      }
      &.meow {
        &:after,
        &:before {
          display: block;
          @include animated(.375s);
          @include fadeInRight;
        }
        &:after {
          animation-delay: .2s;
        }
      }
      &:after {
        content: 'Meow';
        text-transform: uppercase;
        font-family: $oswald;
        letter-spacing: 2px;
        font-size: .875em;
        position: absolute;
        left: -65px;
        top: -10px;
        display: none;
      }
      &:before {
        content: 'Meow';
        display: block;
        text-transform: uppercase;
        font-family: $oswald;
        letter-spacing: 2px;
        font-size: .5em;
        position: absolute;
        left: -35px;
        top: 10px;
        display: none;
      }
      @include breakpoint(1560px, min-width) {
        top: -10%;
        margin-top: 2%;
      }
      @include breakpoint(640px) {
        left: auto;
        top: -55px;
      }
      @include breakpoint(450px) {
        width: 70px;
        top: -35px;
      }
      &:hover {
        .speaker {
          fill: $white;
        }
      }
    }
    &__cat {
      width: 80px;
      top: -5px;
    }
  }
  &__bird {
    transition: .375 ease-in-out;
    position: absolute;
    top: 100px;
    z-index: 10;
    animation-iteration-count: infinite;
    transition: $transition;
    @include animated(10s);
    animation-name: birdFly;
    &--higher {
      width: 75px;
      left: 20%;
      @include breakpoint(800px) {
        left: 10%;
      }
      @include breakpoint(500px) {
        left: 10px;
      }
    }
    &--lower {
      width: 100px;
      right: 20%;
      top: 360px;
      animation-delay: .2s;
      animation-duration: 10.5s;
      animation-direction: reverse;
      @include breakpoint(800px) {
        right: 10%;
        top: 220px;
      }
      @include breakpoint(500px) {
        right: 10px;
      }
    }
  }
}
