.no-touch .projects__project--fixed {
  background-attachment: fixed;
  background-position: 0 200px;
}
.projects__project--fixed + .projects__project--fixed {
  justify-content: flex-start;
  .projects__block {
    background-image: linear-gradient(-180deg, rgba(62,67,111,0.9) 0%, RGBA(215, 33, 62, .90) 100%);
  }
}
.no-touch {
  .projects__two-col .logo {
    opacity: var(--lo);
    transform: translateY(var(--lt));
  }
}
.projects {
  width: 100%;
  position: relative;
  z-index: 1;
  &__project {
    width: 100%;
    position: relative;
    overflow: hidden;
    margin-top: -10px;
    &--dark {
      background: #000;
      min-height: 100vh;
      @include breakpoint(1160px) {
        min-height: 0;
      }
    }
    &--fixed {
      width: 100%;
      background-attachment: scroll;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      min-height: 100vh;
      position: relative;
      z-index: 10;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      overflow: visible;
      @include breakpoint(600px) {
        min-height: auto;
      }
    }
  }
  &__block {
    width: 50%;
    min-width: 600px;
    max-width: 700px;
    padding: 80px;
    background: linear-gradient(-180deg, RGBA(2, 153, 171, .90) 0%, RGBA(254, 64, 1, .90) 100%);
    color: $white;
    position: relative;
    z-index: 100;
    box-shadow: $shadow;
    opacity: var(--bo);
    transform: translateY(var(--bt));
    will-change: opacity transform;
    @include breakpoint(600px) {
      padding: 80px 40px;
      min-width: 100%;
      font-size: 80%;
    }
    h2 {
      @include h2();
      margin-bottom: 20px;
    }
    p {
      line-height: 2;
      margin-bottom: 20px;
      a {
        color: $white;
        font-weight: 700;
        text-decoration: none;
        transition: $dt;
        &:hover {
          opacity: .8;
        }
      }
    }
    .button {
      background: $white;
      color: $purple;
      &:hover {
        transform: scale(1.04);
      }
    }
  }
  &__two-col {
    width: 100%;
    padding: 600px 0 100px;
    position: relative;
    z-index: 300;
    @include breakpoint(1160px) {
      padding: 400px 0 80px;
    }
    @include breakpoint(600px) {
      padding: 200px 0 80px;
      font-size: 80%;
    }
    .container {
      max-width: 1340px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .col {
      width: 47.9%;
      color: $white;
      @include breakpoint(960px) {
        width: 100%;
        padding: 0 140px;
      }
      @include breakpoint(750px) {
        width: 100%;
        padding: 0 40px;
      }
      @include breakpoint(600px) {
        padding: 0 20px;
      }
      @include breakpoint(400px) {
        padding: 0 0px;
      }
      &:last-of-type {
        @include breakpoint(1160px, min-width) {
          padding-left: 140px;
        }
        @include breakpoint(961px, min-width) {
          padding-left: 40px;
        }
        @include breakpoint(960px) {
          margin-top: 30px;
        }
      }
    }
    .logo {
      position: absolute;
      width: 100%;
      left: 0;
      z-index: 100;
      opacity: .12;
      top: 120px;
      @include breakpoint(960px) {
        top: 200px;
      }
    }
    h2 {
      @include h2();
      margin-bottom: 20px;
    }
    p {
      line-height: 2;
      a {
        display: inline-block;
        font-weight: 700;
        text-decoration: none;
        color: $white;
        text-shadow: 0 1px 2px rgba(0,0,0,.3);
        position: relative;
        &:hover {
          &:after {
            background: $red;
            transform: scale(1);
          }
        }
        &:before,
        &:after {
          content: '';
          width: 100%;
          height: 1px;
          background: $white;
          position: absolute;
          left: 0;
          bottom: 5px;
          transform-origin: left;
        }
        &:after {
          background: $red;
          transition: $transition;
          transform: scale(0);
        }
      }
    }
    .preview {
      position: relative;
      width: 100%;
      margin-bottom: 30px;
      border: 1px solid rgba(255,255,255,.2);
      transition: $transition;
      &:hover {
        border-color: $red;
        svg {
          display: inline-block;
          @include animated(.375s);
          @include fadeInUp;
          animation-delay: .2s;
        }
        span {
          display: block;
          @include animated(.375s);
          @include fadeInDown;
        }
      }
      &:after {
        @include content100;
        background: $black-grad;
        opacity: 0;
        transition: $transition
      }
      &__info {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 20;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
      }
      &__link {
        text-align: center;
        text-decoration: none;
        color: $white;
        svg {
          display: none;
          width: 50px;
          height: 50px;
          position: relative;
          z-index: 100;
        }
        &:after {
          @include content100;
          background: $black-grad;
          opacity: .8;
        }
        span {
          width: 100%;
          text-transform: uppercase;
          font-family: $oswald;
          text-transform: uppercase;
          font-weight: 300;
          letter-spacing: 2px;
          display: none;
          text-align: center;
          margin-top: 10px;
          position: relative;
          z-index: 100;
        }
      }
    }
  }
}
.touch {
  .projects__two-col .logo {
    opacity: .1;
    @include breakpoint(960px) {
      opacity: .04;
      top: 100px;
    }
  }
  .video-bg {
    background: url(../resources/reel.gif) no-repeat center top;
    background-size: cover;
  }
  video {
    display: none;
  }
}
.video-bg {
  z-index: 1;
  margin-top: -10px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  &:after {
    content: '';
    position: absolute;
    width: 61.8%;
    height: 100%;
    bottom: -1px;
    left: 0;
    z-index: 10;
    background-image: linear-gradient(-90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
    opacity: .6;
    @include breakpoint(1400px, min-width) {
      width: 100%;
    }
  }
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: -1px;
    left: 0;
    z-index: 10;
    background-image: linear-gradient(-180deg, rgba(0,0,0,.1) 61.8%, rgba(0,0,0,1) 100%);
  }
  video {
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    max-width: 100%;
  }
}
