//Foreground
.foreground {
  display: block;
  width: 100%;
  margin-top: -10px;
  backface-visibility: hidden;
  transform: translateY(var(--ty));
  will-change: transform;
  z-index: 100;
  position: relative;
  @include breakpoint(800px) {
    margin-top: -100px;
  }
  @include breakpoint(600px) {
    margin-top: -60px;
  }
  .container {
    max-width: 940px;
  }
}
