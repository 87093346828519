// Site Typography
@mixin h1($margin : 20px) {
  color: $purple;
  font-family: $oswald;
  font-weight: 700;
  font-size: 4.6em;
  line-height: 1.1;
  text-transform: uppercase;
  letter-spacing: -1px;
  margin-bottom: $margin;
}
@mixin h2($margin : 0px) {
  font-size: 1.5em;;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 10px;
  font-family: $oswald;
  margin-bottom: $margin;
}

@mixin h3($margin : 10px) {
  font-size: 1.125em;
  line-height: 1.5;
  font-weight: 600;
  margin-bottom: $margin;
}
@mixin h4($margin : 10px) {
  font-size: 1.125em;
  line-height: 1.2;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: $margin;
}

@mixin small-header($margin : 20px) {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 600;
  font-size: .75em;
  margin-bottom: $margin;
}

@mixin p($margin : 20px) {
  font-size: 1em;
  line-height: 1.7;
  margin-bottom: $margin;
}

@mixin p-small($margin : 20px) {
  font-size: .875em;
  line-height: 2;
  margin-bottom: $margin;
}

@mixin quote($margin : 20px) {
  font-size: 1.125em;
  line-height: 2;
  font-weight: 600;
  font-style: italic;
  margin-bottom: $margin;
}

@mixin cite($margin : 0px) {
  display: block;
  font-size: .875em;
  font-family: $oswald;
  letter-spacing: 1px;
  margin-top: 10px;
  left: 1.4em;
  margin-bottom: $margin;
}

@mixin ul($margin : 20px) {
  padding-left: 20px;
  margin-bottom: $margin;
  li {
    list-style: disc;
    padding-left: 10px;
    font-size: .875em;
    line-height: 1.6;
  }
}
@mixin ol($margin : 20px) {
  padding-left: 20px;
  margin-bottom: $margin;
  li {
    list-style: decimal;
    font-size: .875em;
    line-height: 1.6;
    padding-left: 10px;
  }
}
@mixin a-link {
  position: relative;
  text-decoration: none;
  &:before {
    content: '';
    width: 100%;
    height: 1px;
    bottom: -1px;
    background: $white;
    position: absolute;
    transition: $dt;
  }
  &:hover {
    &:before {
      transform: scale(0);
    }
  }
}
sup {
  vertical-align: super;
}
