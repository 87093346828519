////////////Lightbox
/* Magnific Popup CSS */

////////////////////////
//      Settings      //
////////////////////////

// overlay
$mfp-overlay-color:                   #000 !default;                    // Color of overlay screen
$mfp-overlay-opacity:                 1 !default;                        // Opacity of overlay screen
$mfp-shadow:                          0 0 2px rgba(0, 0, 0, 0.6) !default; // Shadow on image or iframe

// spacing
$mfp-popup-padding-left:              8px!default ;                        // Padding from left and from right side
$mfp-popup-padding-left-mobile:       6px !default;                        // Same as above, but is applied when width of window is less than 800px

$mfp-z-index-base:                    7000 !default;                       // Base z-index of popup

// controls
$mfp-include-arrows:                  true !default;                       // Include styles for nav arrows
$mfp-controls-opacity:                0.65 !default;                       // Opacity of controls
$mfp-controls-color:                  #FFF !default;                       // Color of controls
$mfp-controls-border-color:           #3F3F3F !default; 	                 // Border color of controls
$mfp-inner-close-icon-color:          #fff !default;                       // Color of close button when inside
$mfp-controls-text-color:             #CCC !default;                       // Color of preloader and "1 of X" indicator
$mfp-controls-text-color-hover:       #FFF !default;                       // Hover color of preloader and "1 of X" indicator
$mfp-IE7support:                      true !default;                       // Very basic IE7 support

// Iframe-type options
$mfp-include-iframe-type:             true !default;                       // Enable Iframe-type popups
$mfp-iframe-padding-top:              60px !default;                       // Iframe padding top
$mfp-iframe-background:               none !default;                       // Background color of iframes
$mfp-iframe-max-width:                100% !default;                      // Maximum width of iframes
$mfp-iframe-ratio:                    9/16 !default;                       // Ratio of iframe (9/16 = widescreen, 3/4 = standard, etc.)

// Image-type options
$mfp-include-image-type:              true !default;                       // Enable Image-type popups
$mfp-image-background:                #444 !default;
$mfp-image-padding-top:               40px !default;                       // Image padding top
$mfp-image-padding-bottom:            40px !default;                       // Image padding bottom
$mfp-include-mobile-layout-for-image: true !default;                       // Removes paddings from top and bottom

// Image caption options
$mfp-caption-title-color:             #F3F3F3 !default;                    // Caption title color
$mfp-caption-subtitle-color:          #BDBDBD !default;                    // Caption subtitle color

// A11y
$mfp-use-visuallyhidden:              false !default;                      // Hide content from browsers, but make it available for screen readers

// Transluscent overlay
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $mfp-z-index-base + 2;
  overflow: hidden;
  position: fixed;
  display: block;
  @include animated(.375s);
  @include fadeIn;
  animation-fill-mode: none;
  background: $black-grad-4;
}

.mfp-removing {
    @include animated(.5s);
    @include fadeOut;
}
// Wrapper for popup
body .mfp-wrap {
  top: 0 !important;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $mfp-z-index-base + 3 !important;
  position: fixed;
  outline: none !important;
  display: block;
  @include animated(.375s);
  @include fadeInUp;
  animation-fill-mode: none;
  -webkit-backface-visibility: hidden; // fixes webkit bug that can cause "false" scrollbar
}
.mfp-removing.mfp-wrap {
    @include animated(.375s);
    @include fadeOutDown;
}
// Root container
.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 $mfp-popup-padding-left;
}

// Vertical centerer helper
.mfp-container {
  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
}
// Remove vertical centering when popup has class `mfp-align-top`
.mfp-align-top {
  .mfp-container {
    &:before {
      display: none;
    }
  }
}
// Popup content holder
.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 8688;
}
.mfp-inline-holder,
.mfp-ajax-holder {
  .mfp-content {
    max-width: 100%;
    cursor: auto;
  }
}
// Cursors
.mfp-ajax-cur {
  cursor: progress;
}
.mfp-zoom-out-cur {
  & {
    cursor: -moz-zoom-out;
    cursor: -webkit-zoom-out;
    cursor: zoom-out;
  }
}
.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}
.mfp-auto-cursor {
  .mfp-content {
    cursor: auto;
  }
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select:none;
  -moz-user-select: none;
  user-select: none;
  transition: $transition;
}

// Hide the image during the loading
.mfp-loading {
  &.mfp-figure {
    display: none;
  }
}

// Helper class that hides stuff
@if $mfp-use-visuallyhidden {
  .mfp-hide {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
  }
} @else {
  .mfp-hide {
    display: none !important;
  }
}
// Preloader and text that displays error messages
.mfp-preloader {
  color: $mfp-controls-text-color;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: $mfp-z-index-base + 4;
  a {
    color: $mfp-controls-text-color;
    &:hover {
      color: $mfp-controls-text-color-hover;
    }
  }
}
// Hide preloader when content successfully loaded
.mfp-s-ready {
  .mfp-preloader {
    display: none;
  }
}
// Hide content when it was not loaded
.mfp-s-error {
  .mfp-content {
    display: none;
  }
}
// CSS-reset for buttons
button {
  &.mfp-close {
    overflow: visible;
    border: 0;
    -webkit-appearance: none;
    outline: none;
    padding: 0;
    z-index: $mfp-z-index-base + 6;
    width: 54px;
    height: 54px;
    text-align: center;
    position: fixed;
    right: 0px;
    top: 0px;
    color: $white;
    background: none;
    cursor: pointer;
    display: block;
    font-size: 40px;
    line-height: 40px;
    transition: $dt;
    &:hover {
      color: $red;
    }
  }
  &::-moz-focus-inner {
      padding: 0;
      border: 0
  }
}

// "1 of X" counter
.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: $mfp-controls-text-color;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

// Navigation arrows
@if $mfp-include-arrows {
  .mfp-arrow {
    position: absolute;
    margin: 0;
    top: 50%;
    margin-top: -30px;
    padding: 0;
    width: 30px;
    height: 60px;
    background: $teal;
    z-index: 9000;
    transition: $dt;
    box-shadow: $shadow;
    &:after {
      content: '';
      width: 20px;
      height: 16px;
      position: absolute;
      left: 50%;
      margin-left: -10px;
      top: 50%;
      margin-top: -8px;
      background: url(../resources/left-arrow-white.svg) no-repeat center center;
    }
    &:hover {
      background: $red;
    }
  }
  .mfp-arrow-left {
    left: 0;
  }
  .mfp-arrow-right {
    right: 0;
    &:after {
      transform: rotate(180deg);
    }
  }
}



// Iframe content type
@if $mfp-include-iframe-type {
  .mfp-iframe-holder {
    padding-top: $mfp-iframe-padding-top;
    padding-bottom: $mfp-iframe-padding-top;
    .mfp-content {
      line-height: 0;
      width: 100%;
      max-width: 1400px;
      @include breakpoint(900px, max-height) {
        max-width: 1000px;
      }
      @include breakpoint(650px, max-height) {
        max-width: 600px;
      }
    }
  }
  .mfp-iframe-scaler {
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: $mfp-iframe-ratio * 100%;
    iframe {
      position: absolute;
      z-index: 9999 !important;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}


// Image content type
@if $mfp-include-image-type {

  /* Main image in popup */
  img {
    &.mfp-img {
      width: auto;
      max-width: 100%;
      height: auto;
      display: block;
      line-height: 0;
      padding: $mfp-image-padding-top 0 $mfp-image-padding-bottom;
      margin: 0 auto;
    }
  }

  /* The shadow behind the image */
  .mfp-figure {
    line-height: 0;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: $mfp-image-padding-top;
      bottom: $mfp-image-padding-bottom;
      display: block;
      right: 0;
      width: auto;
      height: auto;
      z-index: -1;
      background: none;
    }
    small {
      color: $mfp-caption-subtitle-color;
      display: block;
      font-size: 12px;
      line-height: 14px;
    }
    figure {
      margin: 0;
    }
  }
  .mfp-bottom-bar {
    margin-top: -$mfp-image-padding-bottom + 4;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    cursor: auto;
  }
  .mfp-title {
    text-align: left;
    @include p(0);
    color: $mfp-caption-title-color;
    word-wrap: break-word;
    padding-right: 36px; // leave some space for counter at right side
  }

  .mfp-image-holder {
    .mfp-content {
      max-width: 100%;
    }
  }

  .mfp-gallery {
    .mfp-image-holder {
      .mfp-figure {
        cursor: pointer;
      }
    }
  }


  @if $mfp-include-mobile-layout-for-image {
    @media screen and (max-width: 800px) and (orientation:landscape), screen and (max-height: 300px) {
      /**
       * Remove all paddings around the image on small screen
       */
      .mfp-img-mobile {
        .mfp-image-holder {
          padding-left: 0;
          padding-right: 0;
        }
        img {
          &.mfp-img {
            padding: 0;
          }
        }
        .mfp-figure {
          // The shadow behind the image
          &:after {
            top: 0;
            bottom: 0;
          }
          small {
            display: inline;
            margin-left: 5px;
          }
        }
        .mfp-bottom-bar {
          background: rgba(0,0,0,0.6);
          bottom: 0;
          margin: 0;
          top: auto;
          padding: 3px 5px;
          position: fixed;
          &:empty {
            padding: 0;
          }
        }
        .mfp-counter {
          right: 5px;
          top: 3px;
        }
        .mfp-close {
          top: 0;
          right: 0;
          width: 35px;
          height: 35px;
          line-height: 35px;
          background: rgba(0, 0, 0, 0.6);
          position: fixed;
          text-align: center;
          padding: 0;
        }
      }
    }
  }
}



// Scale navigation arrows and reduce padding from sides
@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }
  .mfp-container {
    padding-left: $mfp-popup-padding-left-mobile;
    padding-right: $mfp-popup-padding-left-mobile;
  }
}



// IE7 support
// Styles that make popup look nicier in old IE
@if $mfp-IE7support {
  .mfp-ie7 {
    .mfp-img {
      padding: 0;
    }
    .mfp-bottom-bar {
      width: 600px;
      left: 50%;
      margin-left: -300px;
      margin-top: 5px;
      padding-bottom: 5px;
    }
    .mfp-container {
      padding: 0;
    }
    .mfp-content {
      padding-top: 44px;
    }
    .mfp-close {
      top: 0;
      right: 0;
      padding-top: 0;
    }
  }
}


/* http://prismjs.com/download.html?themes=prism-okaidia&languages=markup+css+clike+javascript+scss */
/**
 * okaidia theme for JavaScript, CSS and HTML
 * Loosely based on Monokai textmate theme by http://www.monokai.nl/
 * @author ocodia
 */

code[class*="language-"],
pre[class*="language-"] {
	color: #f8f8f2;
	text-shadow: 0 1px rgba(0, 0, 0, 0.3);
	font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
	direction: ltr;
	text-align: left;
	white-space: pre;
	word-spacing: normal;
	word-break: normal;
	word-wrap: normal;
	line-height: 1.5;

	-moz-tab-size: 4;
	-o-tab-size: 4;
	tab-size: 4;

	-webkit-hyphens: none;
	-moz-hyphens: none;
	-ms-hyphens: none;
	hyphens: none;
}

/* Code blocks */
pre[class*="language-"] {
	padding: 1em;
	margin: .5em 0;
	overflow: auto;
	border-radius: 0.3em;
}

/* Inline code */
:not(pre) > code[class*="language-"] {
	padding: .1em;
	border-radius: .3em;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
	color: #e6db74;
}

.token.punctuation {
	color: #f8f8f2;
}

.namespace {
	opacity: .7;
}

.token.property,
.token.tag,
.token.constant,
.token.symbol,
.token.deleted {
	color: #5ADBFF;
}

.token.boolean,
.token.number {
	color: #ae81ff;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
	color: #a6e22e;
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string,
.token.variable {
	color: #f8f8f2;
}

.token.atrule,
.token.attr-value,
.token.function {
	color: #e6db74;
}

.token.keyword {
	color: #66d9ef;
}

.token.regex,
.token.important {
	color: #fd971f;
}

.token.important,
.token.bold {
	font-weight: bold;
}
.token.italic {
	font-style: italic;
}

.token.entity {
	cursor: help;
}

.popup {
  position: relative;
  padding: 40px 0;
  width: 100%;
  max-width: 1300px;
  margin: 40px auto;
}
