//Loading Screen
bo1dy {
  &:after {
    content: '';
    width: 100%;
    height: 200%;
    left: 0;
    top: -200px;
    background: #000;
    position: fixed;
    z-index: 999998;
    animation-name: anim;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    transition: .2s all ease-in-out;
  }
  &:before {
    content: 'LOADING...';
    color: #fff;
    font-size: .875em;
    text-align: center;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    padding-top: 110px;
    width: 100px;
    height: 120px;
    right: 20px;
    bottom: 20px;
    position: fixed;
    background: url(../resources/jlax-logo-colored.svg) no-repeat center top;
    background-size: contain;
    z-index: 999999;
    animation-name: move;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    transition: $dt;
  }
  &.loaded {
    &:after {
      transform: translateX(100%);
    }
    &:before {
      opacity: 0;
    }
  }
  &.remove {
    &:after, &:before {
      display: none;
    }
  }
}
@keyframes move{
	0% {transform: translateY(0px);}
  50% {transform: translateY(-20px);}
	100% {transform: translateY(0px);}
}
