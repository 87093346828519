.no-touch {
  .waypoint {
    position: absolute;
    left: 0;
    bottom: calc(-50vh - 200px);
    width: 100%;
    height: 1px;
    opacity: 0;
  }
}
